import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { HelpersService } from "../../shared/services/helpers.service";
import {LocalStorageService} from '../services/local-storage.service';
import {Observable} from 'rxjs/internal/Observable';
import {AuthService} from '../services/auth.service';
import {RESPONSE_OK} from '../types/system';
import {SuccessfulAuthResponse} from '../models/responses';
import {HubService} from '../services/hub.service';
import {KeycloakService} from "keycloak-angular";
import {NotificationService} from "../services/notification.service";
import {environment} from "../../../environments/environment";


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private ls: LocalStorageService,
    private authService: AuthService,
    private hubService: HubService,
    private router: Router,
    private helpers: HelpersService,
    private keycloakService: KeycloakService,
    private _notificationSvc: NotificationService,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    let isTokenOk = false;
    let isMeOk = true;

    let mePromise: Promise<any> = Promise.resolve(true);

    let tokenPromise = this.keycloakService.getToken().then((token) => {
      const isTokenExpired = this.keycloakService.isTokenExpired();

      if (token && !isTokenExpired) {
        isTokenOk = true;
        this.authService.isAuth$.next(true);

        if (!this.ls.token || (this.ls.token !== token) || !this.ls.email) {
          this.ls.token = token;

          if (this.shouldRemoveAgency()) {
            this.ls.removeAgency();
          }

          mePromise = this.hubService.getMe().then((response: any) => {
            if (response && response.status === RESPONSE_OK) {
              response = <SuccessfulAuthResponse>response;
              this.authService.updateUserData(response);
              this.authService.isUserDataReceived.next(true);
            }
          }).catch(err => {
            isMeOk = false;
            if (err.error && err.error.error && typeof err.error.error === 'string') {
              this._notificationSvc.error('', err.error.error, err.status);
            }
            this.authService.isUserDataReceived.next(false);
            if (this.helpers.isCriticalError(err)) {
              throw err;
            }
          });
        }
      } else {
        this.authService.logout();
      }
    }).catch(err => {
      if (this.helpers.isCriticalError(err)) {
        throw err;
      }
    });

    return tokenPromise.then(() => {
      return mePromise.then(() => isMeOk && isTokenOk);
    });
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }

  private shouldRemoveAgency(): boolean {
    const keycloakInstance = this.keycloakService.getKeycloakInstance();
    return (
      this.ls.agency &&
      this.ls.email &&
      keycloakInstance?.idTokenParsed?.email !== this.ls.email
    );
  }
}
